@import url("https://fonts.googleapis.com/css?family=Nunito:600,700&display=swap");

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

li {
	list-style: none;
}

body {
	font-family: Nunito, Roboto, Arial, sans-serif;
	background: #191c25;
	color: white;
}

#app {
	height: 100%;
	display: flex;
	justify-content: center;
}

.container {
	text-align: center;
	color: #fff;
	margin-top: 50px;
	width: 350px;
}

.container-receipt {
	text-align: center;
	color: #fff;
	margin-top: 50px;
	margin-bottom: 15px;
	margin-left: 15px;
	margin-right: 15px;
	width: 100%;
	max-width: 800px;
	border-top: 10px solid #4aaebf;
	border-bottom: 10px solid #4aaebf;
	border-left: 5px solid #4aaebf;
	border-right: 5px solid #4aaebf;
	padding: 20px;
}

.container-receipt > header {
	font-size: 1.3rem;
	font-weight: 700;
	margin: 0 auto 60px auto;
	position: relative;
	height: 30px;
	width: 250px;
	overflow: hidden;
	color: #000;
}
/* 
.container_1 {
	text-align: center;
	color: #fff;
	margin-top: 100px;
	width: 350px;
} */

.container > header {
	font-size: 1.3rem;
	font-weight: 700;
	margin: 0 auto 60px auto;
	position: relative;
	height: 30px;
	width: 250px;
	overflow: hidden;
}

.header-headings {
	display: flex;
	flex-direction: column;
	position: absolute;
	width: 100%;
	height: auto;
	transition: all 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.header-headings > span {
	margin: 3px 0;
}

.header-headings.sign-in {
	transform: translateY(0);
}

.header-headings.sign-up {
	transform: translateY(-35px);
}

.header-headings.forgot {
	transform: translateY(-75px);
}

.options {
	display: flex;
	align-items: center;
	width: 350px;
}

@media screen and (max-width: 380px) {
	.options {
		width: 100%;
	}
	.receipt-download-CTA {
		padding: 10px !important;
		background: #4bafbf52;
		border: 1px dotted;
		border-radius: 12px;
		margin-top: 25px;
		font-size: 12px !important;
		font-weight: 900;
		cursor: pointer;
		width: 100%;
	}
}

.options > li {
	cursor: pointer;
	opacity: 0.5;
	transition: all 0.2s ease;
}

.options > li:hover {
	opacity: 1;
}

.options > li.active {
	opacity: 1;
}

.options > li:nth-of-type(2) {
	margin-left: 15px;
}

.options > li:last-of-type {
	margin-left: auto;
}

.account-form {
	display: flex;
	flex-direction: column;
	margin-top: 30px;
	margin-right: 30px;
	margin-left: 30px;
}

.account-form-fields {
	display: flex;
	flex-direction: column;
	overflow: hidden;
	transition: all 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
	width: 100%;
}

.account-form-fields.sign-in {
	max-height: 123px;
}

.account-form-fields.sign-up {
	max-height: 250px;
}

.account-form-fields-readonly > input {
	border: 0;
	margin-bottom: 10px;
	padding: 15px;
	font-size: 1rem;
	font-family: Nunito, sans-serif;
	color: #000;
	font-weight: bold;
	background-color: #f9f9f9;
}

.account-form-fields.forgot {
	max-height: 61px;
}

.account-form-fields > input {
	border: 0;
	margin-bottom: 10px;
	padding: 15px;
	font-size: 1rem;
	font-family: Nunito, sans-serif;
	color: #000;
	/* border-radius: 5px; */
}

.account-form-fields > input::-moz-placeholder {
	color: #aaa;
}

.account-form-fields > input:-ms-input-placeholder {
	color: #aaa;
}

.account-form-fields > input::placeholder {
	color: #aaa;
}

.account-form-fields > input::-webkit-input-placeholder {
	color: #aaa;
}

.account-form-fields > input:-ms-input-placeholder {
	color: #aaa;
}

.account-form-fields > input:focus {
	outline: none;
}

.btn-submit-form {
	border: 0;
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	cursor: pointer;
	padding: 15px 0;
	border-radius: 5px;
	color: #fff;
	font-size: 1rem;
	font-family: Nunito, sans-serif;
	background: #6381e8;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.13);
	transition: all 0.2s ease;
}

.btn-submit-form:hover {
	background: #4468e4;
}

.btn-submit-form:active,
.btn-submit-form:focus {
	outline: none;
	background: #2e56e0;
}

footer {
	position: fixed;
	width: 100%;
	bottom: 0;
	left: 0;
	padding: 30px 0;
	text-align: center;
}

footer > a {
	color: #fff;
	font-weight: 700;
}

.forgot-passowrd {
	display: block;
	text-align: right;
	margin: 5px; 
	color: white !important;
	margin-bottom: 30px;
	margin-right: 23px;
}

.forgot-passowrd a {
	display: block;
	text-align: right;
	margin: 5px;
	color: white !important;

}
.btn-submit-form a {
	text-decoration: none;
	color: white;
}

.error-text {
	color: crimson;
	padding: 0;
	margin: 0;
	margin-bottom: 10px;
	font-size: 12px;
	font-weight: bold;
}

.otpInput {
	margin: 9px;
}

.otpInput input {
	width: 40px !important;
	height: 40px;
}

.rupee-symbol {
	height: 52px;
	background: white;
	font-size: 38px;
	color: #7c7272;
	padding-left: 10px;
}

.receipt-logo {
	padding-right: 90%;
	padding-top: 20px;
}

.text-pan {
	display: flex;
	color: #000;
	border-top: 2px solid #4bafbf;
	border-bottom: 2px solid #4bafbf;
}
.receipt-text-label {
	width: 50%;
	text-align: start;
}
.receipt-donor-name {
	color: #000;
	text-align: left;
	/* border-bottom: 1px dotted; */
	display: flex;
	text-decoration: dotted;
	text-decoration-color: #000;
	text-decoration-line: underline;
	text-decoration-thickness: from-font;
	text-decoration-style: 10px;
	line-height: 50px;
}

.receipt-thankyou-msg {
	color: #000;
	text-align: start;
	font-size: 12px;
}

.receipt-information-section {
	background-color: #4aaebf14;
	color: #000;
	text-align: start;
	padding: 10px;
}

.receipt-information-section-pending {
	background-color: #ff8d0026;
	color: #000;
	text-align: start;
	padding: 10px;
}

.receipt-information-section-rejected {
	background-color: #ff000021;
	color: #000;
	text-align: start;
	padding: 10px;
}

.receipt-information-name {
	color: #000;
	text-align: start;
	border-bottom: 1px dotted #000;
	font-size: 14px;
	line-height: 20px;
}

.receipt-information-80g {
	color: #000;
	text-align: center;
	/* border-bottom: 1px dotted #000; */
	font-size: 10px;
	line-height: 12px;
	padding-bottom: 10px;
	padding-top: 10px;
}
.receipt-information-contact {
	font-size: 12px;
}

.receipt-information-name span {
	font-size: 10px;
}

.receipt-download-CTA {
	padding: 15px;
	background: #4bafbf52;
	border: 1px dotted;
	border-radius: 12px;
	margin-top: 25px;
	margin-right: 66%;
	font-size: 16px;
	font-weight: 900;
	cursor: pointer;
	width: 300px;
}
